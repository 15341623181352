import projectsTypeResolvers from './projectsTypeResolvers';

export default {
  billa: {
    projectId: '24686fb7-4a5c-01ca-7f96-2e7f1ae5e501',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImM4ZGY3YjkxNzMyZTQ4YmE4ZjE5MWFmNTI3MWRhMTIxIiwNCiAgImlhdCI6ICIxNTUxOTY2MjI0IiwNCiAgImV4cCI6ICIxODk3NTY2MjI0IiwNCiAgInByb2plY3RfaWQiOiAiMjQ2ODZmYjc0YTVjMDFjYTdmOTYyZTdmMWFlNWU1MDEiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.0IpWX6TFxZohH8TrylQyY-dw7fmAi5_ZU_Qpjotl4Gg',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  bawag: {
    projectId: '49b7ee70-1c51-0151-eaf1-601cef9ae4c5',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjA1N2JjNTk2NzZiZDQ0OTk4NTk5OGI2Zjg0ZWE4ZGU1IiwNCiAgImlhdCI6ICIxNTUzNjc0NzM0IiwNCiAgImV4cCI6ICIxODk5Mjc0NzM0IiwNCiAgInByb2plY3RfaWQiOiAiNDliN2VlNzAxYzUxMDE1MWVhZjE2MDFjZWY5YWU0YzUiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.ZJZR5TxL51Y9wYWFvEsiMCdpu-eRu6AzLfROkZ7dh1k',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  bipa: {
    projectId: 'eb773334-2d5d-012e-1939-2eb3bac82d76',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImExNmNiNzBhYTVmYTQwZmM5YzYyNWZkYmVkMzZkY2EyIiwNCiAgImlhdCI6ICIxNTUxOTY2NDg4IiwNCiAgImV4cCI6ICIxODk3NTY2NDg4IiwNCiAgInByb2plY3RfaWQiOiAiZWI3NzMzMzQyZDVkMDEyZTE5MzkyZWIzYmFjODJkNzYiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.dZBgJZ9MAJWgacWTNOXIi2stVjnslTn0Zbm6plbKnMk',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  adeg: {
    projectId: '5e324bd4-1618-01c6-97fe-f86ada13e4d8',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImM4ZGNjZmYzZDhkNDQyOTQ5YWJjNGIwMDVkN2IxMDI3IiwNCiAgImlhdCI6ICIxNTUzNjc0NzI2IiwNCiAgImV4cCI6ICIxODk5Mjc0NzI2IiwNCiAgInByb2plY3RfaWQiOiAiNWUzMjRiZDQxNjE4MDFjNjk3ZmVmODZhZGExM2U0ZDgiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.TioBPdiIp0UxhkwC0KCBWDrvdPf_wu1fk6AkO5yiaAg',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  interio: {
    projectId: '9ec384fd-cf96-01a0-6ac5-7a8742fab95c',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImQyMzNhNmE3NjE2ODQwY2RhY2Y2NDg0MTljYWNkYmQ3IiwNCiAgImlhdCI6ICIxNTUzNjc4OTEzIiwNCiAgImV4cCI6ICIxODk5Mjc4OTEzIiwNCiAgInByb2plY3RfaWQiOiAiOWVjMzg0ZmRjZjk2MDFhMDZhYzU3YTg3NDJmYWI5NWMiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.oO7_5qcmSNNAxRLLMONMO7e7HZvPokwGadFjYHoRNX8',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  libro: {
    projectId: '174c625b-f1d4-0131-2b01-5b137a951dd2',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImM3YWM0MDBjOTcwMzRhN2FhYzJjMTJmYTZmNTMyMmYyIiwNCiAgImlhdCI6ICIxNTUzNjc5NDAwIiwNCiAgImV4cCI6ICIxODk5Mjc5NDAwIiwNCiAgInByb2plY3RfaWQiOiAiMTc0YzYyNWJmMWQ0MDEzMTJiMDE1YjEzN2E5NTFkZDIiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.AWPcuWOgKURPhOJ4WpGWAT2z_d1o8XwPr6DKWUnx9mc',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  merkur: {
    projectId: '0549f540-3c7b-018c-2fb3-9289fc566a9c',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjZlMjRiODBiODM4NDQzOGE5ODZjMGY4NTI3MzZmYjIxIiwNCiAgImlhdCI6ICIxNTUxOTY2OTk4IiwNCiAgImV4cCI6ICIxODk3NTY2OTk4IiwNCiAgInByb2plY3RfaWQiOiAiMDU0OWY1NDAzYzdiMDE4YzJmYjM5Mjg5ZmM1NjZhOWMiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.JgnqDuKqaUiXMnk2Bq-mhqezND6W2KGazHmJdxzSeKg',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  mjam: {
    projectId: '07ff4b72-2a57-017f-236b-9e06bece7951',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjlmNjYzZDJlNDBlZjQ1ZDViZGQyMDU3N2Y4MTdkMjRlIiwNCiAgImlhdCI6ICIxNTc5MDE1MDQzIiwNCiAgImV4cCI6ICIxOTI0NjE1MDQzIiwNCiAgInByb2plY3RfaWQiOiAiMDdmZjRiNzIyYTU3MDE3ZjIzNmI5ZTA2YmVjZTc5NTEiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.THLrUvclV4_gS8g5HRs-FtkwwicoJRysu878FlRQnRA',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  omv: {
    projectId: 'dbdbed33-5f73-01f8-9e66-276c3bf252b7',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjkxZmQwY2JhMjI3OTQ0ZWFhMjVlY2ViMzNjOTc2NzhlIiwNCiAgImlhdCI6ICIxNTUzNjc5NDk0IiwNCiAgImV4cCI6ICIxODk5Mjc5NDk0IiwNCiAgInByb2plY3RfaWQiOiAiZGJkYmVkMzM1ZjczMDFmODllNjYyNzZjM2JmMjUyYjciLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.AoeefMmbd1XD16cnidqzs2TuTZ3i2Q4kNR04FwD9fzY',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  pagro: {
    projectId: '6285c19a-540f-01f2-13c4-a9a34b653b85',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjlkMDY2ZmRlNjY3ZTQ5OWViMGY1ZTYxZTlkZjQ3MDgxIiwNCiAgImlhdCI6ICIxNTUzNjc5NTU2IiwNCiAgImV4cCI6ICIxODk5Mjc5NTU2IiwNCiAgInByb2plY3RfaWQiOiAiNjI4NWMxOWE1NDBmMDFmMjEzYzRhOWEzNGI2NTNiODUiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.Kfm1vV5D_wBr3M-XqB-3RcVUGZ-seHr9RHzMunIBwz4',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  pearle: {
    projectId: 'b872b3ae-8daf-014e-3be3-2a487fbea52d',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImJkZjBhMmM3YjJjOTQxZWRiYTA1NzkwNjJkN2IyNDI0IiwNCiAgImlhdCI6ICIxNTY4MTEyMTAwIiwNCiAgImV4cCI6ICIxOTEzNzEyMTAwIiwNCiAgInByb2plY3RfaWQiOiAiYjg3MmIzYWU4ZGFmMDE0ZTNiZTMyYTQ4N2ZiZWE1MmQiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.N4mpjCAh1WA3uLmL4l3A2lyAsOtHND821WSqwku4oa0',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  penny: {
    projectId: '9b280ae7-69bb-01de-32b0-ade436f755be',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjE0MmE1MDZlOWYxNzQxNTg4N2I3ZDI1YTQ2MTA2ZTI2IiwNCiAgImlhdCI6ICIxNTUxOTY3MDY2IiwNCiAgImV4cCI6ICIxODk3NTY3MDY2IiwNCiAgInByb2plY3RfaWQiOiAiOWIyODBhZTc2OWJiMDFkZTMyYjBhZGU0MzZmNzU1YmUiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.8y-Z_Z4uTVQ8nEGzOOq7HUpZlDM7x7nzYwJu2FhMS78',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  universal: {
    projectId: '541237a2-18b8-0126-4aa9-2f64698a642e',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImJlODdmNzFmNTFiOTRiOWM4Y2ZlMmQ5MWI4MTNmYmM1IiwNCiAgImlhdCI6ICIxNjAwMDY5MzkzIiwNCiAgImV4cCI6ICIxOTQ1NjY5MzkzIiwNCiAgInByb2plY3RfaWQiOiAiNTQxMjM3YTIxOGI4MDEyNjRhYTkyZjY0Njk4YTY0MmUiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.gY_9k5sEcizjLsV4dRgMw6XMwD9igmIdvuc3czODYLk',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  uoebc: {
    projectId: '840e50e1-4b3c-0126-a865-16578eea53e7',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjI0ODY4YzcxZmY4ZjQzNmRiODUwNDc1NzI2NGI0MWExIiwNCiAgImlhdCI6ICIxNTUzNjc5MjcyIiwNCiAgImV4cCI6ICIxODk5Mjc5MjcyIiwNCiAgInByb2plY3RfaWQiOiAiODQwZTUwZTE0YjNjMDEyNmE4NjUxNjU3OGVlYTUzZTciLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.EccFrSF-y6BbFkt31XtjHJy4lS41_uyEHQHI02yZNCY',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  verbund: {
    projectId: 'cbea13d1-2001-01d3-a485-d78203a53d35',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjMxNDRlNDMxZjMyMDQ5N2E4NmU4Njk1OWJmZDA1MjU2IiwNCiAgImlhdCI6ICIxNTY3MDg4MjE0IiwNCiAgImV4cCI6ICIxOTEyNjg4MjE0IiwNCiAgInByb2plY3RfaWQiOiAiY2JlYTEzZDEyMDAxMDFkM2E0ODVkNzgyMDNhNTNkMzUiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.TjJr99eUCM6J2c2AmQrA6AO-AYEsvm5w-3HFk5nsqsc',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  zgonc: {
    projectId: 'c79670f8-c7e5-0150-437c-7aabc0fa89d1',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjViZTJhYzVjNjEyNTQyOTc4NGEyNWYzMjAyZTMxZWI0IiwNCiAgImlhdCI6ICIxNTYwNzg1MjY2IiwNCiAgImV4cCI6ICIxOTA2Mzg1MjY2IiwNCiAgInByb2plY3RfaWQiOiAiYzc5NjcwZjhjN2U1MDE1MDQzN2M3YWFiYzBmYTg5ZDEiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.WqnVkvrdpZVmPJW6K_MrH_LSEk-sopy4RU0NmleSzxM',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  billaReisen: {
    projectId: 'd67b895e-63c7-01a8-22db-ab025f61bff7',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImViOWRlODJhMDhhMzQyMzliMjcwYmVmZDhmMTA0NzNkIiwNCiAgImlhdCI6ICIxNTU0MTI2MjMzIiwNCiAgImV4cCI6ICIxODk5NzI2MjMzIiwNCiAgInByb2plY3RfaWQiOiAiZDY3Yjg5NWU2M2M3MDFhODIyZGJhYjAyNWY2MWJmZjciLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.GP50_DjmOsM2iUpHAReYkqjKgFJv-vHqnFGNtr3dMtQ',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  allianz: {
    projectId: '956e099e-d211-01ec-7253-fcec72a0ddf0',
    previewApiKey:
      'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjk3ZDAwZjA2YTMzMzQzMmJhNjRlZGQzYmIwZDNlOThmIiwNCiAgImlhdCI6ICIxNTgxMDYwOTk5IiwNCiAgImV4cCI6ICIxOTI2NjYwOTk5IiwNCiAgInByb2plY3RfaWQiOiAiOTU2ZTA5OWVkMjExMDFlYzcyNTNmY2VjNzJhMGRkZjAiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.BxuNMJRmlCZd55cH5YaQumsvC0-D16_d1htyfljgihw',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  joBonuswelt: {
    projectId: '8ff3d8ed-b1fc-0194-abdc-b17f4a58d6b3',
    previewApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzYjVlMTRmYjU0MDI0OTQxOTY1MmNiOWFhOWJiNTc2ZiIsImlhdCI6MTU1MjMwNTUyOSwibmJmIjoxNTUyMzA1NTI5LCJleHAiOjE4OTc5MDU1MjksInZlciI6IjEuMC4wIiwicHJvamVjdF9pZCI6IjhmZjNkOGVkYjFmYzAxOTRhYmRjYjE3ZjRhNThkNmIzIiwiYXVkIjoicHJldmlldy5kZWxpdmVyLmtlbnRpY29jbG91ZC5jb20ifQ.dG-NPGHo-lO4T8mFTkqefpCO8cdtryKCjGkC6K00g28',
    // 'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjNiNWUxNGZiNTQwMjQ5NDE5NjUyY2I5YWE5YmI1NzZmIiwNCiAgImlhdCI6ICIxNTUyMzA1NTI5IiwNCiAgImV4cCI6ICIxODk3OTA1NTI5IiwNCiAgInByb2plY3RfaWQiOiAiOGZmM2Q4ZWRiMWZjMDE5NGFiZGNiMTdmNGE1OGQ2YjMiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.D6NSnbe7QUN_naYfwfpHVmQy6o-VLluyKnRlZXExnhM',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  joeUndGo: {
    projectId: 'f179b856-5c53-0175-ea3e-ee2798282237',
    previewApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmYWM0ZDI5YWYzMjQ0MmIyOTg2OTdkODM4NzgyYjA2MyIsImlhdCI6MTY0NjE0OTc4NywibmJmIjoxNjQ2MTQ5Nzg3LCJleHAiOjE5OTE3NDk3ODcsInZlciI6IjEuMC4wIiwicHJvamVjdF9pZCI6ImYxNzliODU2NWM1MzAxNzVlYTNlZWUyNzk4MjgyMjM3IiwiYXVkIjoicHJldmlldy5kZWxpdmVyLmtlbnRpY29jbG91ZC5jb20ifQ.S_w80kde2o9CZ59h2q2gi0Tzo_9HxBABztnbW9AZf2c',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  sportPass: {
    projectId: '440f32e6-1c89-010b-b019-384900530d36',
    previewApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhNTg4OGZiZWE4OTM0YTVkODU5OTQ4NTRiNjljMGRhNCIsImlhdCI6MTcyNzY3OTE4NSwibmJmIjoxNzI3Njc5MTg1LCJleHAiOjE4ODU0NDU1MjAsInZlciI6IjIuMC4wIiwic2NvcGVfaWQiOiIxMzAyOWJkYjUyOGQ0ODRmYjY5YTM0MTI5YTE5OTQ2MCIsInByb2plY3RfY29udGFpbmVyX2lkIjoiMDZkODU2YzMyZDJhMDE3YjE1NmVjYTU1ZmM2YjBkYmMiLCJhdWQiOiJkZWxpdmVyLmtvbnRlbnQuYWkifQ.Lg20j7OVnAdwAvBKPDjFcMO1axf7GN2UVaxIAF-rCEM',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
  joRelsen: {
    projectId: '802cf49a-b660-010e-974a-3ccdb85d96b4',
    previewApiKey:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyY2JiYzY1N2E2NDA0MDRlYTg2ODdhNzIyMGYzMzA0MyIsImlhdCI6MTcyODAzODY0MCwibmJmIjoxNzI4MDM4NjQwLCJleHAiOjE4ODU3OTY3NjAsInZlciI6IjIuMC4wIiwic2NvcGVfaWQiOiJkMzZkNTEwN2VkZWU0ODJlYjAyNGQ2OGE3YjcyNWE2NCIsInByb2plY3RfY29udGFpbmVyX2lkIjoiYmJiYmI2MmNkYjNlMDE2MjYzZDQ0NzA2NmI4YTkyNjciLCJhdWQiOiJkZWxpdmVyLmtvbnRlbnQuYWkifQ.Tq7IcCsF-9Wcd4XhgDY3P9SotEu9L9NDRRPX0YAMQoE',
    globalQueryConfig: {
      usePreviewMode: true,
    },
    typeResolvers: projectsTypeResolvers.voucherTypeResolvers,
  },
};
